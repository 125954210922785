<template>
  <div>
    <el-card>
      <div class="menu-box">
        <h2>入库审批管理</h2>
        <div class="box-bd">
          <el-row :gutter="20">
            <el-col :span="3">
              <el-button type="success" @click="multipleApproval"
                >批量通过申请</el-button
              >
            </el-col>
            <el-col :span="3">
              <el-popover
                v-model="visible"
                placement="right"
                width="100%"
                trigger="click"
              >
                <div>
                  <div>
                    <el-form :model="query.where_list">
                      <el-form-item label-width="90px" label="商品名称">
                        <el-input
                          size="mini"
                          v-model="query.where_list.mp_name"
                          placeholder="商品名称"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label-width="90px" label="商品编号">
                        <el-input
                          size="mini"
                          v-model="query.where_list.number"
                          placeholder="商品名称"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label-width="90px" label="入库单号">
                        <el-input
                          size="mini"
                          v-model="query.where_list.numbering"
                          placeholder="商品名称"
                        ></el-input>
                      </el-form-item>
                      <el-form-item label-width="90px" label="申请时间段">
                        <el-date-picker
                          size="mini"
                          v-model="query.where_list.add_time"
                          type="daterange"
                          value-format="yyyy-MM-dd"
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期"
                        >
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item label-width="90px" label="审批状态">
                        <el-select
                          v-model="query.where_list.state"
                          style="width: 100%"
                          size="mini"
                          placeholder="请选择审批状态"
                          clearable
                        >
                          <el-option
                            v-for="item in approvalStatus"
                            :key="item.value"
                            :label="item.name"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div
                    class="footer"
                    style="display:flex;justify-content: end;"
                  >
                    <el-button size="mini" type="text" @click="visible = false"
                      >取消</el-button
                    >
                    <el-button type="primary" size="mini" @click="searchList()"
                      >确定</el-button
                    >
                  </div>
                </div>
                <el-button type="primary" slot="reference">高级搜索</el-button>
              </el-popover>

              <!-- <el-button type="primary" @click="showSearch">高级搜索</el-button> -->
            </el-col>
            <el-col :span="3">
              <el-button type="warning" @click="dialogLog.state = true"
                >查看日志</el-button
              >
            </el-col>
          </el-row>
          <el-table
            class="approval-table"
            ref="approvalRef"
            @selection-change="handleChange"
            :data="approvalList"
            :row-key="getRowKeys"
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
              :selectable="selectable"
            />
            <el-table-column prop="mp_name" width="140" label="商品名称" />

            <el-table-column prop="type" width="120" label="入库类型" />
            <el-table-column prop="mp_spec_name" label="规格名" />
            <el-table-column prop="add_time" width="120" label="申请时间" />
            <el-table-column prop="purchaser_name" label="采购员" />

            <!-- <el-table-column prop="edit_time" label="审核时间">
              <template slot-scope="scope">
                {{
                  scope.row.edit_time === '' ? '等待审核' : scope.row.edit_time
                }}
              </template>
            </el-table-column> -->
            <el-table-column prop="remark" label="备注" />
            <el-table-column prop="product_date" width="120" label="生产日期" />
            <el-table-column prop="quality_guarantee_period" label="保质期" />
            <el-table-column prop="quantity" width="90" label="入库数量" />

            <el-table-column prop="status" width="90" label="审批状态">
              <template slot-scope="scope">
                <!-- <el-switch
              v-model="scope.row.state"
              :active-value="1"
              :inactive-value="2"
              @change="changeState(scope.row)"
            /> -->

                <el-tag v-if="scope.row.status === 1" type="success"
                  >已通过</el-tag
                >
                <el-tag v-if="scope.row.status === 2" type="danger"
                  >已拒绝</el-tag
                >
                <el-tag v-if="scope.row.status === 0" type="warning"
                  >待审核</el-tag
                >
              </template>
            </el-table-column>

            <el-table-column label="操作" width="300">
              <template slot-scope="scope">
                <el-button
                  v-if="scope.row.status === 0"
                  size="mini"
                  type="success"
                  icon="el-icon-check"
                  @click="pass(scope.row)"
                  >通过</el-button
                >

                <el-button
                  v-if="scope.row.status === 0"
                  size="mini"
                  type="danger"
                  icon="el-icon-close"
                  @click="decline(scope.row)"
                  >拒绝</el-button
                >
                <el-button
                  size="mini"
                  icon="el-icon-search"
                  @click="openInfo(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <!-- fenye -->
          <el-pagination
            :page-sizes="[2, 5, 10]"
            :pager-count="15"
            style="margin:15px 0 15px 15px;"
            layout="total, sizes, prev, pager, next"
            :page-size="query.limit"
            background
            :total="exp.num"
            @current-change="pageChange"
            @size-change="sizeChange"
          />
        </div>
      </div>
    </el-card>
    <!-- 详情-->
    <el-dialog
      title="入库单详情"
      :before-close="handleClose"
      :visible.sync="addDialogVisible"
      width="80%"
    >
      <el-descriptions>
        <el-descriptions-item label="商品名称">{{
          row.mp_name
        }}</el-descriptions-item>

        <el-descriptions-item label="入库类型">{{
          approvalInfo.type
        }}</el-descriptions-item>
        <el-descriptions-item label="入库单号">{{
          approvalInfo.number
        }}</el-descriptions-item>
        <el-descriptions-item label="仓库">{{
          row.warehouse_name
        }}</el-descriptions-item>
        <el-descriptions-item label="单价">{{
          row.unit_price
        }}</el-descriptions-item>
        <el-descriptions-item label="审批状态">
          <el-tag size="mini" v-if="approvalInfo.status === 2" type="success"
            >已通过</el-tag
          >
          <el-tag size="mini" v-if="approvalInfo.status === 1">部分审批</el-tag>
          <el-tag size="mini" v-if="approvalInfo.status === 3" type="danger"
            >已拒绝</el-tag
          >
          <el-tag size="mini" v-if="approvalInfo.status === 0" type="warning"
            >待审核</el-tag
          >
        </el-descriptions-item>
        <el-descriptions-item label="采购员">{{
          row.purchaser_name
        }}</el-descriptions-item>
        <el-descriptions-item label="单位">{{ row.unit }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAdd()">取 消</el-button>
        <el-button type="primary" @click="confirmAdd()">确 认</el-button>
      </span>
    </el-dialog>
    <all-log :dialogLog="dialogLog" />
  </div>
</template>

<script>
import {
  inboundApprovalList,
  getInboundApprovalSearchFields,
  getInboundApprovalSearchboxList,
  inboundApprovalInfo,
  approvalPass,
  refuseApproval,
} from '@/api/psi/whapproval.js'
export default {
  data() {
    return {
      dialogLog: {
        state: false,
      },
      approvalStatus: [
        {
          value: 0,
          name: '待审批',
        },
        {
          value: 1,
          name: '审批通过',
        },
        {
          value: 2,
          name: '审批拒绝',
        },
        {
          value: 'all',
          name: '状态不限',
        },
      ],
      visible: false,
      approvalList: [],
      query: {
        // 跳转页码
        page_code: 1,
        // 每页几条数据
        page_num: 10,
        // 当前页数
        page: 1,
        where_list: {
          state: '',
          add_time: '',
          mp_name: '',
          number: '',
          numbering: '',
        },
      },
      exp: {
        // 总条数
        num: 0,
        // 分页页码
        page: 1,
        // 当前页
        count: 1,
      },
      addDialogVisible: false,
      approvalInfo: {},
      checkedItem: [],
      row: {},
      ids: [],
      searchFields: {},
    }
  },
  created() {
    this.getApprovalList()
    this.getInboundApprovalSearchFields()
  },
  methods: {
    async getInboundApprovalSearchFields() {
      const { data: res } = await getInboundApprovalSearchFields()
      console.log(res)
      this.searchFields = res.data
    },
    async getApprovalList() {
      const { data: res } = await inboundApprovalList(this.query)
      console.log(res)
      this.approvalList = res.data
      this.exp = res.exp
    },
    // 页面改变监听事件
    pageChange(value) {
      // this.query.page为当前页
      this.query.page = value
      this.getApprovalList()
    },
    // 页面每一页条数改变事件
    sizeChange(value) {
      this.query.page_num = value
      this.getApprovalList()
    },
    selectable(row) {
      //console.log(row)
      if (row.status === 0) {
        return true
      } else {
        return false
      }
    },
    multipleApproval() {
      this.ids = []
      if (this.checkedItem.length === 0) {
        this.$message.warning('批量同意入库审批：请选择需要同意的审批申请！')
      } else {
        this.$confirm('确认批量通过入库申请?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'info',
        })
          .then(async () => {
            this.checkedItem.forEach((item) => {
              this.ids.push(item.id)
            })
            const res = await approvalPass({ ids: this.ids })
            if (res.data.code === 1) {
              this.$message.success('已批量通过入库申请!')
              this.$refs.approvalRef.clearSelection()
              this.getApprovalList()
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消通过',
            })
          })
      }
    },
    getRowKeys(row) {
      //   console.log(row)
      // this.rowKeyArr.push(row.id)
      return row.id
    },
    // 通过
    async pass(row) {
      this.$confirm('确认通过?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info',
      })
        .then(async () => {
          const res = await approvalPass({ ids: [row.id] })
          if (res.data.code === 1) {
            this.$message.success('已通过该入库申请!')

            this.$refs.approvalRef.clearSelection()
            this.getApprovalList()
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消通过',
          })
        })
    },
    // 拒绝
    async decline(row) {
      this.$prompt('请输入拒绝原因', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(async ({ value }) => {
          const res = await refuseApproval({ ids: [row.id], content: value })
          if (res.code === 1) {
            this.$message.success('已拒绝该入库申请!')
            this.$refs.approvalRef.clearSelection()

            this.getApprovalList()
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '取消拒绝',
          })
        })
    },

    // 打开详情
    async openInfo(row) {
      this.addDialogVisible = true
      const res = await inboundApprovalInfo({ id: row.id })
      console.log(res)
      this.approvalInfo = res.data.data
      this.row = row
    },
    handleClose(row) {
      this.addDialogVisible = false
      this.approvalInfo = {}
    },
    handleChange(val) {
      console.log(val)
      this.checkedItem = val
    },
    searchList() {
      let time = this.query.where_list.add_time
      if (time.length === 2) {
        time = time[0] + '--' + time[1]
        this.query.where_list.add_time = time
      }
      this.getApprovalList()
      this.visible = false
    },
  },
}
</script>

<style scoped>
.approval-table >>> .el-checkbox {
  zoom: 200% !important;
}
</style>
