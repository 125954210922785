import { post } from '@/utils/request'

//获取入库审批列表
export function inboundApprovalList(data) {
    return post('admin/storage/inboundApprovalList', data)
}
//获取入库审批搜索字段数据
export function getInboundApprovalSearchFields(data) {
    return post('admin/commonapi/getInboundApprovalSearchFields', data)
}
//获取入库审批搜索框数据
export function getInboundApprovalSearchboxList(data) {
    return post('admin/commonapi/getInboundApprovalSearchboxList', data)
}
//入库审批详情
export function inboundApprovalInfo(data) {
    return post('admin/storage/inboundApprovalInfo', data)
}
///审批通过
export function approvalPass(data) {
    return post('admin/storage/approvalPass', data)
}
// 审批拒绝
export function refuseApproval(data) {
    return post('admin/storage/refuseApproval', data)
}






